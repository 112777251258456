import React, { useEffect } from "react";
import { FaInstagram, FaTiktok, FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import HeroHafsa from "../picture/heroHafsa.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <div
      data-aos="fade-down"
      className="bg-cover bg-center grid md:grid-cols-2 grid-cols-1  p-8"
    >
      <div
        data-aos="fade-right"
        data-aos-delay="800"
        className="flex flex-col gap-8  justify-center items-center"
      >
        <h1 className="hafsaName drop-shadow-lg text-center xl:text-7xl lg:text-6xl md:text-4xl text-4xl font-bold">
          Hafsa Official
        </h1>
        <h2 className="shadow-lg text-center xl:text-2xl lg:text-xl md:text-sm text-sm bg-[#ae927c] py-3 px-4 mx-auto font-bold rounded-md text-slate-50">
          UGC CREATOR/Instagram INFLUENCER
        </h2>
        <div className="flex items-center justify-center gap-4">
          <div className="flex flex-col items-center justify-center gap-4 animate-bounce">
            <a
              href="https://www.instagram.com/hafsaofficial___/"
              target="_blank"
            >
              <FaInstagram className="text-[#ae927c] text-6xl" />
              <p className="text-md drop-shadow-lg font-semibold mt-1">+50K</p>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 animate-bounce">
            <a href="https://www.facebook.com/hafssa.official" target="_blank">
              <FaFacebook className="text-[#ae927c] text-6xl" />
              <p className="text-md drop-shadow-lg font-semibold mt-1">+400</p>
            </a>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 animate-bounce">
            <a href="https://www.tiktok.com/@hafsaofficial_" target="_blank">
              <FaTiktok className="text-[#ae927c] text-6xl" />
              <p className="text-md drop-shadow-lg font-semibold mt-1">+2200</p>
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center  gap-2 animate-bounce">
            <MdEmail className="text-3xl" />
            <a
              href="mailto:hafsadaanani@gmail.com"
              className="hover:underline duration-200 drop-shadow-lg font-semibold"
            >
              hafsadaanani@gmail.com
            </a>
          </div>
          <div className="flex items-center  gap-2">
            <FaMapMarkerAlt className="text-3xl" />
            <p className="drop-shadow-lg font-semibold">Casablanca</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center h-[80%] mt-14">
        <img
          src={HeroHafsa}
          alt="HeroHafsa"
          className="rounded-tl-3xl rounded-br-3xl shadow-lg hover:scale-105 duration-300 cursor-pointer shadow-[#ae927c] border-8 border-[#ae927c]"
        />
      </div>
    </div>
  );
};

export default Hero;
