import analytic1 from "../picture/analytic/analytic1.png";
import analytic2 from "../picture/analytic/analytic2.png";
import analytic3 from "../picture/analytic/analytic3.png";
import analytic4 from "../picture/analytic/analytic4.png";

export const AnalyticsMedia = [
  {
    id: 1,
    image: analytic1,
  },
  {
    id: 2,
    image: analytic2,
  },
  {
    id: 3,
    image: analytic3,
  },
  {
    id: 4,
    image: analytic4,
  },
];
