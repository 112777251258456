import message1 from "../picture/clientsmessages/message1.png";
import message2 from "../picture/clientsmessages/message2.png";
import message3 from "../picture/clientsmessages/message3.png";
import message4 from "../picture/clientsmessages/message4.png";
import message5 from "../picture/clientsmessages/message5.png";


export const ClientsMessages = [
  {
    id: 1,
    image: message1,
  },
  {
    id: 2,
    image: message2,
  },
  {
    id: 3,
    image: message3,
  },
  {
    id: 4,
    image: message4,
  },
  {
    id: 5,
    image: message5,
  },
];
