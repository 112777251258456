
export const MyOfferedServices = [
  {
    id: 1,
    name: "Demo Videos",
  },
  {
    id: 2,
    name: "Lifestyle Videos",
  },
  {
    id: 3,
    name: "Testimonials",
  },
  {
    id: 4,
    name: "Voiceovers",
  },
  {
    id: 5,
    name: "Outfit Videos",
  },
  {
    id: 6,
    name: "ASMR",
  },
  {
    id: 7,
    name: "Unboxing Videos",
  },
  {
    id: 8,
    name: "Product Photos",
  },
  {
    id: 9,
    name: "Script Reading",
  },
  {
    id: 10,
    name: "Spark Ads",
  },
];
