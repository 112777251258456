import img1 from "../picture/lifestyle/img1.jpg";
import vid1 from "../picture/lifestyle/vid1.mp4";
import img2 from "../picture/lifestyle/img2.jpg";
import vid2 from "../picture/lifestyle/vid2.mp4";
import img3 from "../picture/lifestyle/img3.jpg";
import vid3 from "../picture/lifestyle/vid3.mp4";
import img4 from "../picture/lifestyle/img4.jpg";
import vid4 from "../picture/lifestyle/vid4.mp4";
import img5 from "../picture/lifestyle/img5.jpg";
import vid5 from "../picture/lifestyle/vid5.mp4";
import img6 from "../picture/lifestyle/img6.jpg";
import vid6 from "../picture/lifestyle/vid6.mp4";
import img7 from "../picture/lifestyle/img7.jpg";
import vid7 from "../picture/lifestyle/vid7.mp4";

export const LifestyleMedia = [
  {
    id: 1,
    url: vid1,
    poster: img1,
    description: "Working",
  },
  {
    id: 2,
    url: vid2,
    poster: img2,
    description: "Week End morning",
  },
  {
    id: 3,
    url: vid3,
    poster: img3,
    description: "Healthy morning breakfast",
  },
  {
    id: 4,
    url: vid4,
    poster: img4,
    description: "Summer Travel",
  },
  {
    id: 5,
    url: vid5,
    poster: img5,
    description: "bokhor ben barkhia",
  },
  {
    id: 6,
    url: vid6,
    poster: img6,
    description: "Zarbiya maghribiya",
  },
  {
    id: 7,
    url: vid7,
    poster: img7,
    description: "Bondagani",
  },
];
