import React, { useEffect } from "react";
import { PreviousClientsMedia } from "../data/PreviousClientsMedia";
import { ClientsMessages } from "../data/ClientsMessage";
import Aos from "aos";
import "aos/dist/aos.css";

const PreviousClients = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-left"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute top-2 bg-inherit px-4">
        PreviousClients
      </h1>
      <h2 className="font-semibold ml-6 text-slate-600">
        Checkout their feedback!
      </h2>
      <div className="grid xl:grid-cols-2 grid-cols-1">
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 p-6">
          {PreviousClientsMedia.map((client, index) => {
            return (
              <div
                key={index}
                className="rounded-xl p-2 flex flex-col items-center justify-center gap-2"
              >
                <img
                  className="shadow-lg rounded-full hover:scale-110 duration-300 cursor-pointer"
                  src={client.image}
                  alt={client.id}
                />
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 p-6 gap-3">
          {ClientsMessages.map((client, index) => {
            return (
              <div
                key={index}
                className="rounded-xl p-2 flex flex-col items-center justify-center gap-2"
              >
                <img
                  className="shadow-lg w-[80%] hover:scale-110 duration-300 cursor-pointer"
                  src={client.image}
                  alt={client.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreviousClients;
