import Hero from "./components/Hero";
import Skincare from "./components/Skincare";
import LifeStyle from "./components/LifeStyle";
import Fashion from "./components/Fashion";
import UgcPhotography from "./components/UgcPhotography";
import PreviousClients from "./components/PreviousClients";
import Analytics from "./components/Analytics";
import OfferedServices from "./components/OfferedServices";
import LetsworkTogether from "./components/LetsworkTogether";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="container mx-auto overflow-hidden">
      <Hero />
      <Skincare />
      <LifeStyle />
      <Fashion />
      <UgcPhotography />
      <PreviousClients />
      <Analytics />
      <OfferedServices />
      <LetsworkTogether />
      <Footer />
    </div>
  );
}

export default App;
