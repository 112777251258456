import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const LetsworkTogether = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-right"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute right-0 top-2 bg-inherit px-4">
        Let's work Together
      </h1>
      <h2 className="font-semibold ml-6 text-slate-600 absolute right-8 top-14">
        I can't wait to be a part of your brand success.
      </h2>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 p-6 mt-12">
        <div className="bg-slate-50 p-4 flex flex-col gap-3 justify-center items-center">
          <h1 className="font-semibold capitalize text-2xl">email me :</h1>
          <a
            href="mailto:hafsadaanani@gmail.com"
            className="text-xl animate-bounce hover:underline duration-200 drop-shadow-lg font-semibold"
          >
            hafsadaanani@gmail.com
          </a>
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <h1 className="text-center font-semibold">
            +5 years of content creation experience
          </h1>
          <h1 className="text-center font-semibold">
            Social Media Creative Director
          </h1>
          <h1 className="text-center font-semibold">
            +5 years product photography and videography
          </h1>
        </div>
        <div></div>
      </div>
      <h1 className="sm:text-3xl text-xl font-bold px-4 py-2 capitalize">
        What to expect when working with me!
      </h1>
      <div className="flex flex-col gap-6 p-6">
        <p>
          I will become your target customer by understanding the ins and outs
          of your brand and ideal customer, from their pains and desires to
          their character traits.
        </p>
        <p>
          I'll create captivating, compelling, and high-quality video and/or
          photo content that clearly communicates how your product/brand is the
          solution for your audience by touching on pain points that resonate
          and connect with your consumer alongside a visual that your audience
          deeply desires leading to conversions.
        </p>
        <p>
          I'll ensure the content I create for you will be visually inviting and
          leave the viewers wanting more, hereby increasing your engagement and
          sales.
        </p>
      </div>
    </div>
  );
};

export default LetsworkTogether;
