import React, { useEffect } from "react";
import { MyOfferedServices } from "../data/MyOfferedServices";
import Aos from "aos";
import "aos/dist/aos.css";

const OfferedServices = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-left"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute top-2 bg-inherit px-4">
        Offered Services
      </h1>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 p-6">
        <div className="grid grid-cols-2 gap-3">
          {MyOfferedServices.map((service, index) => {
            return (
              <h1
                key={index}
                className="hover:scale-105 duration-300 cursor-pointer shadow-lg text-center xl:text-2xl lg:text-xl md:text-sm text-sm bg-[#ae927c] py-3 px-4 mx-auto font-bold rounded-md text-slate-50 w-full"
              >
                {service.name}
              </h1>
            );
          })}
        </div>
        <div className="bg-[#ae927c] p-4 text-slate-50 flex flex-col items-center justify-center gap-6 shadow-lg">
          <h1 className="underline font-semibold text-center">
            UGC DIRECT RESPONSE MARKETING + ORGANIC CONCEPT VIDEOS
          </h1>
          <p className="text-center">
            10-60 SECOND SHORT FORM VIDEO VERTICAL FORMAT 9:16
          </p>
          <p className="text-center">
            INCLUDES: FILMING, EDITING, HOOK, CTA'S, SOUNDS, TEXT TO SCREEN,
            VOICE OVERS
          </p>
        </div>
      </div>
      <h1 className="p-4 text-center text-xl">3-5 business day turnaround time.</h1>
    </div>
  );
};

export default OfferedServices;
