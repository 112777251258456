import React, { useEffect } from "react";
import { UgcPhotographyMedia } from "../data/UgcPhotographyMedia";
import Aos from "aos";
import "aos/dist/aos.css";

const UgcPhotography = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-right"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute top-2 right-0 bg-inherit px-4">
        UGC Photography
      </h1>
      <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 my-12 mx-8">
        {UgcPhotographyMedia.map((file, index) => {
          return (
            <div>
              <div
                key={index}
                className="border-8 rounded-3xl border-[#ae927c] shadow-lg overflow-hidden cursor-pointer group "
              >
                {
                  <img
                    src={file.url}
                    className="group-hover:scale-125 scale-110 duration-200"
                  />
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UgcPhotography;
