import img1 from "../picture/fashion/img1.jpg";
import vid1 from "../picture/fashion/vid1.mp4";
import img2 from "../picture/fashion/img2.jpg";
import vid2 from "../picture/fashion/vid2.mp4";
import img3 from "../picture/fashion/img3.jpg";
import vid3 from "../picture/fashion/vid3.mp4";
import img4 from "../picture/fashion/img4.jpg";
import vid4 from "../picture/fashion/vid4.mp4";

export const FashionMedia = [
  {
    id: 1,
    url: vid1,
    poster: img1,
    description: "Beldi Outfit",
  },
  {
    id: 2,
    url: vid2,
    poster: img2,
    description: "Summer Outfit",
  },
  {
    id: 3,
    url: vid3,
    poster: img3,
    description: "Takchita Marocain Outfit",
  },
  {
    id: 4,
    url: vid4,
    poster: img4,
    description: "Jalaba Marocain Outfit",
  },
];
