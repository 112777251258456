import React, { useEffect, useState, useRef } from "react";
import iphone from "../picture/iPhone.png";
import { FaPlay, FaPause } from "react-icons/fa";
import { FashionMedia } from "../data/FashionMedia";
import Aos from "aos";
import "aos/dist/aos.css";

const Fashion = () => {
  const [playing, setPlaying] = useState(null); // Track the currently playing video ID
  const videoRefs = useRef([]);

  const handlePlay = (videoId, index) => {
    // Pause the currently playing video, if any
    if (playing !== null && videoRefs.current[playing]) {
      videoRefs.current[playing].pause();
    }

    // Play the clicked video
    videoRefs.current[index].play();
    setPlaying(index); // Set the currently playing video to the clicked one
  };

  const handlePause = () => {
    if (videoRefs.current[playing]) {
      videoRefs.current[playing].pause();
    }
    setPlaying(null); // Reset the state to stop playing
  };
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-left"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute top-2 bg-inherit px-4">
        Fashion
      </h1>
      <div className="grid sm:grid-cols-3 grid-cols-1 max-w-7xl justify-items-center gap-12 my-12 mx-8">
        {FashionMedia.map((video, index) => {
          return (
            <div key={index} className="w-[200px] h-[400px] relative">
              <video
                poster={video.poster}
                ref={(el) => (videoRefs.current[index] = el)}
                src={video.url}
                className="absolute z-10 left-1 top-2 rounded-[37px] w-[95%]"
                paused={playing !== index} // Ensure video is paused if it's not the currently playing one
              />
              <img className="absolute z-20" src={iphone} alt="" />
              <div>
                {playing === index ? (
                  <FaPause
                    className="absolute z-30 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-black/60 rounded-full p-4 size-12 text-white text-3xl hover:scale-125 duration-300"
                    onClick={handlePause}
                  />
                ) : (
                  <FaPlay
                    className="absolute z-30 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-black/60 rounded-full p-4 size-12 text-white text-3xl hover:scale-125 duration-300"
                    onClick={() => handlePlay(video.id, index)}
                  />
                )}
              </div>
              <div className="absolute -bottom-4 bg-[#ae927c] text-slate-50 text-center w-full h-[60px] flex justify-center items-center rounded capitalize hafsaName">
                {video.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Fashion;
