import img1 from "../picture/wellness/img1.jpg";
import vid1 from "../picture/wellness/vid1.mp4";
import img2 from "../picture/wellness/img2.jpg";
import vid2 from "../picture/wellness/vid2.mp4";
import img3 from "../picture/wellness/img3.jpg";
import vid3 from "../picture/wellness/vid3.mp4";
import img4 from "../picture/wellness/img4.jpg";
import vid4 from "../picture/wellness/vid4.mp4";

export const SkincareMedia = [
  {
    id: 1,
    url: vid1,
    poster: img1,
    description: "take care of your face",
  },
  {
    id: 2,
    url: vid2,
    poster: img2,
    description: "take care of your hair in case of loss",
  },
  {
    id: 3,
    url: vid3,
    poster: img3,
    description: "night routine",
  },
  {
    id: 4,
    url: vid4,
    poster: img4,
    description: "moment cocooning",
  },
];
