import client1 from "../picture/PreviousClients/client1.png";
import client2 from "../picture/PreviousClients/client2.png";
import client3 from "../picture/PreviousClients/client3.png";
import client4 from "../picture/PreviousClients/client4.png";
import client5 from "../picture/PreviousClients/client5.png";
import client6 from "../picture/PreviousClients/client6.png";
import client7 from "../picture/PreviousClients/client7.png";
import client8 from "../picture/PreviousClients/client8.png";
import client9 from "../picture/PreviousClients/client9.png";
import client10 from "../picture/PreviousClients/client10.png";
import client11 from "../picture/PreviousClients/client11.png";
import client12 from "../picture/PreviousClients/client12.png";
import client13 from "../picture/PreviousClients/client13.png";
import client14 from "../picture/PreviousClients/client14.png";
import bondagani from "../picture/PreviousClients/Bondagani.png";
import ben_barkhia from "../picture/PreviousClients/ben_barkhia.jpg";
import julia_rugs from "../picture/PreviousClients/julia_rugs.jpg";

export const PreviousClientsMedia = [
  {
    id: 1,
    image: client1,
  },
  {
    id: 2,
    image: client2,
  },
  {
    id: 3,
    image: client3,
  },
  {
    id: 4,
    image: client4,
  },
  {
    id: 5,
    image: client5,
  },
  {
    id: 6,
    image: client6,
  },
  {
    id: 7,
    image: client7,
  },
  {
    id: 8,
    image: client8,
  },
  {
    id: 9,
    image: client9,
  },
  {
    id: 10,
    image: client10,
  },
  {
    id: 11,
    image: client11,
  },
  {
    id: 12,
    image: client12,
  },
  {
    id: 13,
    image: client13,
  },
  {
    id: 14,
    image: client14,
  },
  {
    id: 15,
    image: bondagani,
  },
  {
    id: 16,
    image: ben_barkhia,
  },
  {
    id: 17,
    image: julia_rugs,
  },
];
