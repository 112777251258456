import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#fae2cf] py-6 my-4 w-full">
      <h1 className="text-center sm:text-xl text-sm px-4 py-2">
        &copy; All Right Reserved
        <a
          href="/"
          className="hafsaName mx-2 hover:underline duration-300 capitalize font-bold text-slate-800"
        >
          Hafsa Official
        </a>
        2024 Developed by
        <a
          href="https://www.taoufikmarki.com"
          target="_blank"
          className="font-bold text-slate-500 mx-2 hover:underline duration-300"
        >
          Taoufik Marki
        </a>
      </h1>
    </footer>
  );
};

export default Footer;
