import React, { useEffect } from "react";
import { AnalyticsMedia } from "../data/AnalyticsMedia";
import Aos from "aos";
import "aos/dist/aos.css";

const Analytics = () => {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div
      data-aos="fade-right"
      className="bg-[#fae2cf] py-6 relative rounded-md shadow-lg my-4"
    >
      <hr className="border-black border-t-2 mx-2 my-4" />
      <h1 className="hafsaName text-3xl font-bold absolute right-0 top-2 bg-inherit px-4">
        Analytics
      </h1>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 p-6">
        {AnalyticsMedia.map((analytic) => {
          return (
            <div
              key={analytic.id}
              className="rounded-xl p-2 flex flex-col items-center justify-center gap-2"
            >
              <img
                className="shadow-lg hover:scale-110 duration-300 cursor-pointer"
                src={analytic.image}
                alt={analytic.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Analytics;
