import img1 from "../picture/UgcPhotography/1.jpg";
import img2 from "../picture/UgcPhotography/2.jpg";
import img3 from "../picture/UgcPhotography/3.jpg";
import img4 from "../picture/UgcPhotography/4.jpg";
import img5 from "../picture/UgcPhotography/5.jpg";
import img6 from "../picture/UgcPhotography/6.jpg";
import img7 from "../picture/UgcPhotography/7.jpg";
import img8 from "../picture/UgcPhotography/8.jpg";


export const UgcPhotographyMedia = [
  {
    id: 1,
    url: img1,
  },
  {
    id: 2,
    url: img2,
  },
  {
    id: 3,
    url: img3,
  },
  {
    id: 4,
    url: img4,
  },
  {
    id: 5,
    url: img5,
  },
  {
    id: 6,
    url: img6,
  },
  {
    id: 7,
    url: img7,
  },
  {
    id: 8,
    url: img8,
  },
];
